import axios, { reLogin } from '../axios'

/**
 * 检测药品名字是否通过
 * @param {string} name
 */
async function drugCheck (name) {
  if (!name) return null
  try {
    const { data } = await axios.get(`/drug/check?name=${name}`)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
/**
 * @param {object} params
 * @param {string} byEffect
 * @param {string} depot
 * @param {string} dosage
 * @param {string} effect
 * @param {string} fitPeople
 * @param {string} name
 * @param {string} pic
 * @param {string} sig
 * @param {string} specifications
 * @param {string} notes
 * @param {string} indication
 */
async function drugCreate (params) {
  if (!params) return null
  try {
    const { data } = await axios.post('/drug/create', params)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
/**
 * @param {object} params
 * @param {number} pn
 * @param {number} ps
 * @param {string} keyword
 */
async function drugList (params) {
  if (!params) return null
  try {
    const { data } = await axios.get('/drug/list', { params })
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
/**
 * @param {object} params
 * @param {string} byEffect
 * @param {string} depot
 * @param {string} dosage
 * @param {string} effect
 * @param {0} id
 * @param {string} name
 * @param {string} pic
 * @param {string} sig
 * @param {string} specifications
 */
async function drugPut (params) {
  if (!params) return null
  try {
    const { data } = await axios.put('/drug/put', params)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
/**
 * @param {number} id
 */
async function drugDelete (id) {
  if (!id) return null
  try {
    const { data } = await axios.delete(`/drug/${id}`)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}

/**
 * 数据的上移下移
 * @param {number} id 数据id
 * @param {string} type 类型 diet news popular qa
 * @param {boolean} direction true为上移 false为下移
 */
async function putOrder (params) {
  try {
    const { data } = await axios.put('order', params)
    if (data.status !== 'ok') {
      reLogin(data.errorMsg)
      return false
    }
    return true
  } catch (e) {
    return false
  }
}

export {
  drugCheck,
  drugCreate,
  drugList,
  drugPut,
  drugDelete,
  putOrder
}
