<template>
  <div>
    <el-row>
      <el-col :span="24" v-if="isShowForm">
        <Form :isModify="isModify" :data="modifyData" @successAdd="handleAdd" @onClose="handleClose" @onModify="handleModify"/>
      </el-col>
      <el-col :span="24" v-else>
        <el-button class="add-btn" size="mini" type="primary" @click="chageIsShowForm(true)">添加</el-button>
        <Table
          class="table-wrapper"
          title="药品"
          hiddenMove
          :tableCols="getTableCols()"
          :tableData="listData"
          :onEdit="handleEdit"
          :onDelete="handleDelete"
          :currentPage="pageNo"
          :total="total"
          :onSizeChange="handlePageSize"
          :onCurrentPageChange="handlePageNo"
        ></Table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Table from '../table'
import Form from './form.vue'
import { tableCols } from './config'
import {
  // drugCheck,
  // putOrder,
  drugCreate,
  drugList,
  drugPut,
  drugDelete
} from '../../api/apis/drug'
export default {
  name: 'Drug',
  components: { Table, Form },
  data () {
    return {
      listData: [],
      pageNo: 1,
      pageSize: 10,
      keyword: '',
      isShowForm: false,
      total: 0,
      isModify: false,
      modifyData: null
    }
  },
  created () {
    this.getList()
  },
  computed: {
    params () {
      return {
        pn: this.pageNo,
        ps: this.pageSize,
        keyword: this.keyword
      }
    }
  },
  watch: {
    params () {
      this.getList()
    }
  },
  methods: {
    // async handleMove (index, data, direction) {
    //   const status = await putOrder({ type: 'diet', id: data.id, direction })
    //   status && this.getList()
    // },
    handleClose () {
      this.isModify = false
      this.isShowForm = false
    },
    chageIsShowForm (bool) {
      this.isShowForm = bool
    },
    getTableCols () {
      return tableCols
    },
    async getList () {
      const data = await drugList(this.params)
      if (!data) return
      const { content: { total, list } } = data
      this.total = total
      this.listData = list.map(x => ({ ...x, createUser: x?.create?.realName || '', updateUser: x?.update?.realName || '' }))
    },
    // 修改后的最新数据
    async handleModify (data) {
      console.log(data)
      const { byEffect, depot, dosage, effect, id, name, sig, specifications, notes, fitPeople, pic } = data
      const { status } = await drugPut({ fitPeople, byEffect, depot, dosage, effect, id, name, sig, specifications, notes, pic })
      if (status === 'ok') {
        this.$message.success('修改成功')
        this.isModify = false
        this.isShowForm = false
        this.getList()
      } else {
        this.$message.error('修改失败')
      }
    },
    handleEdit (data) {
      this.modifyData = data
      this.isModify = true
      this.isShowForm = true
    },
    async handleDelete (row) {
      const { status } = await drugDelete(row.id)
      if (status === 'ok') {
        this.$message.success('删除成功')
        this.getList()
      } else {
        this.$message.error('删除失败')
      }
    },
    async handleAdd (data) {
      const { status } = await drugCreate(data)
      if (status === 'ok') {
        this.$message.success('添加成功')
        this.isShowForm = false
        this.getList()
      } else {
        this.$message.error('添加失败')
      }
    },
    handlePageSize (pageSize) {
      this.pageSize = pageSize
    },
    handlePageNo (pageNo) {
      this.pageNo = pageNo
    }
  }
}
</script>

<style>
</style>
