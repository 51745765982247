<template>
  <div class="card">
  <p class="title"><i class="fa fa-th-large fa-lg"></i>药品</p>
  <i class='delete-icon el-icon-close' @click="() => {resetForm();$emit('onClose');}"></i>
  <el-form :model="ruleForm2" status-icon :rules="rules" ref="ruleForm2" label-width="100px" class="demo-ruleForm">
    <el-form-item label="药品名" prop="name">
      <el-input clearable v-model="ruleForm2.name" autocomplete="off"></el-input>
    </el-form-item>
    <!-- <el-form-item label="主治疾病" prop="indication">
      <el-input type='textarea' autosize clearable v-model="ruleForm2.indication" autocomplete="off"></el-input>
    </el-form-item> -->
    <!-- <el-form-item label="用法用量" prop="sig">
      <div class="flex">
        <el-select v-model="sig1" placeholder="请选择">
          <el-option
            v-for="item in options1()"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <div class="other-text"></div>
        <el-select v-model="sig2" placeholder="请选择">
          <el-option
            v-for="item in options2()"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <div class="other-text">次,每次</div>
        <el-select v-model="sig3" placeholder="请选择">
          <el-option
            v-for="item in options3()"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-input clearable v-model="sig4" autocomplete="off"></el-input>
      </div>
    </el-form-item> -->
    <el-form-item label="功效" prop="effect">
      <el-input clearable type='textarea' autosize v-model="ruleForm2.effect" autocomplete="off"></el-input>
    </el-form-item>
    <!-- <el-form-item label="副作用" prop="byEffect">
      <el-input clearable type='textarea' autosize v-model="ruleForm2.byEffect" autocomplete="off"></el-input>
    </el-form-item> -->
    <el-form-item label="适应人群" prop="fitPeople">
      <el-input clearable v-model="ruleForm2.fitPeople" autocomplete="off"></el-input>
    </el-form-item>
    <!-- <el-form-item label="规格" prop="specifications">
      <div class="flex">
        <el-input clearable v-model="specifications1" autocomplete="off"></el-input>
        <div class="other-text">×</div>
        <el-input clearable v-model="specifications2" autocomplete="off"></el-input>
      </div>
    </el-form-item> -->
    <!-- <el-form-item label="剂量" prop="dosage">
      <el-input clearable v-model="ruleForm2.dosage" autocomplete="off"></el-input>
    </el-form-item> -->
    <el-form-item label="存储方式" prop="depot">
      <el-input clearable v-model="ruleForm2.depot" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="注意事项" prop="notes">
      <el-input clearable v-model="ruleForm2.notes" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="药品图片上传">
      <el-upload
        class="avatar-uploader"
        style="height: auto"
        :drag="true"
        multiple
        :action="getUrl()"
        :data="{fileType: 'reveal'}"
        :headers="{ Authentication: this.$store.state.authentication }"
        :on-success="handlePicSuccess"
        :on-remove="handleRemove"
        :before-upload="beforePicUpload">
        <img v-for="pic in pics" :key="pic" :src="pic" class="avatar">
        <i class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm('ruleForm2')">确 定</el-button>
      <el-button @click="resetForm">重 置</el-button>
    </el-form-item>
  </el-form>
  </div>
</template>

<script>
import { getBaseUrl } from '../../api/baseConfig'
import { drugCheck } from '../../api/apis/drug'
import { options1, options2 } from './config'
export default {
  name: 'Form',
  props: {
    data: {
      default: {}
    },
    isModify: {
      default: false
    }
  },
  created () {
    if (!this.isModify) return
    const { name, indication, fileVOS, pic, depot, notes, effect, byEffect, dosage, fitPeople } = this.data
    this.pics = fileVOS?.map(({ absPath }) => absPath)
    this.ruleForm2 = {
      name,
      indication,
      depot,
      notes,
      effect,
      byEffect,
      dosage,
      fitPeople,
      pic: pic.split(',')
    }
  },
  data () {
    const validateName = async (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入药品'))
        return
      }
      const { status } = await drugCheck(value)
      if (status !== 'ok') {
        callback(new Error('该药品已存在,请重新输入'))
      } else {
        callback()
      }
    }
    return {
      pics: [],
      // specifications1: '',
      // specifications2: '',
      // sig1: '每日',
      // sig2: '1',
      // sig3: '1',
      // sig4: '片',
      ruleForm2: {
        name: '',
        // sig: '',
        effect: '',
        byEffect: '',
        fitPeople: '',
        dosage: '',
        depot: '',
        indication: '',
        notes: '',
        // specifications: '',
        pic: []
      },
      rules: {
        name: [
          { required: true, validator: validateName, trigger: 'blur' }
        ],
        indication: [
          { required: true, message: '请输入主治疾病', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    options1 () {
      return options1
    },
    options2 () {
      return options2
    },
    options3 () {
      return options2
    },
    getUrl () {
      return `${getBaseUrl()}/files/upload`
    },
    handleRemove (file, fileList) {
      this.pics = this.pics.filter(x => x !== file.response.content[0].absPath)
      this.ruleForm2.pic = this.ruleForm2.pic.filter(x => x !== file.response.content[0].relativePath)
    },
    async handlePicSuccess (res, file) {
      const [imgInfo] = res.content || []
      if (imgInfo) {
        const { absPath, relativePath } = imgInfo
        this.pics.push(absPath)
        this.ruleForm2.pic.push(relativePath)
        this.$message.success('上传成功')
      }
    },
    beforePicUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!(isJPG || isPNG)) {
        this.$message.error('上传药品图片只能是 JPG/PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return (isLt2M && isJPG) || isPNG
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          this.$message.error('添加失败！')
        } else {
          const obj = {}
          for (const key in this.ruleForm2) {
            let val = this.ruleForm2[key]
            if (Array.isArray(val)) {
              val = val.join(';')
            }
            obj[key] = val
          }
          // const sig = `${this.sig1}${this.sig2}次，每次${this.sig3}${this.sig4}`
          // const specifications = `${this.specifications1}×${this.specifications2}`
          this.isModify ? this.$emit('onModify', { ...this.data, ...obj }) : this.$emit('successAdd', obj)
        }
      })
    },
    resetForm () {
      this.pics = []
      this.ruleForm2 = {
        name: '',
        // sig: '',
        effect: '',
        byEffect: '',
        fitPeople: '',
        pic: []
      }
    }
  }
}
</script>

<style>
  .flex {
    display: flex;
  }
  .other-text{
    font-size: 20px;
    margin: 0 4px;
    white-space: nowrap;
  }
</style>
