/**
 * {
    prop: 'specifications',
    label: '规格',
    modify: true,
    input: 2
  },
  {
    prop: 'sig',
    label: '用法用量',
    modify: true
  },
 */
export const tableCols = [{
  prop: 'name',
  label: '药品',
  width: 120
}, {
  prop: 'effect',
  label: '功效',
  width: 200
}, {
  prop: 'fitPeople',
  label: '适应人群'
}, {
  prop: 'depot',
  label: '储存方式'
}, {
  prop: 'notes',
  label: '注意事项'
}, {
  prop: 'fileVOS',
  label: '图片',
  type: 'img',
  width: 80
}, {
  prop: 'createUser',
  label: '创建人',
  width: 70
}, {
  prop: 'createTime',
  label: '创建时间',
  isSort: true
}, {
  prop: 'updateUser',
  label: '更新人',
  width: 70
}, {
  prop: 'updateTime',
  label: '更新时间',
  isSort: true
}]

export const options1 = [{
  value: '每日',
  label: '每日'
}, {
  value: '每周',
  label: '每周'
}, {
  value: '每月',
  label: '每月'
}]

export const options2 = Array(9).fill(1).map((_, idx) => ({ value: (idx + 1).toString(), label: (idx + 1).toString() }))
